import * as React from "react"
import { Box,  Container, Flex,  Image, Spacer } from '@chakra-ui/react'
import { Link } from "gatsby"
import BurgerBar from "./burgerBar"

export const SiteHeader = () => (
  <Container zIndex={1000} position='fixed' as="header" height={20} backgroundColor={'black'} width={'100%'}  maxW={'unset'}>
    <Flex>
      <Box mt={5}>
        <BurgerBar />
      </Box>
      <Spacer/>
      <Box>  
      <Link to="/">
        <Image src={'/logo.png'} alt={'Unique Baby Blankets'} height={20} />
      </Link>
      </Box>
      <Spacer/>
      <Box>
        
      </Box>
  </Flex>
  </Container>
)