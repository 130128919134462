import React, { useRef } from "react";
import { Link } from "gatsby";

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  VStack,
  IconButton,
  Box,
  Divider,

} from '@chakra-ui/react'
import { HamburgerIcon } from "@chakra-ui/icons";

function BurgerBar() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()

  return (
    <>
      <IconButton
        aria-label="Open Menu"
        fontSize='30px'
        mr={2}
        colorScheme='black'
        icon={<HamburgerIcon color={'white'} />}
        onClick={onOpen}
      />

      <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        finalFocusRef={btnRef}
        colorScheme="black"
        backgroundColor={'black'}
      >
        <DrawerOverlay backgroundColor={'blackAlpha.300'} />
        <DrawerContent backgroundColor={'black'} color={'gray.200'}>
          <DrawerCloseButton />
          <DrawerHeader></DrawerHeader>
          <DrawerBody fontSize={17} mt={10} fontFamily={'Roboto'}>
            <VStack display={'block'}>
              <Divider />
              <Box m={3} _hover={{ fontWeight: "500" }} >
                <Link to="/" onClick={onClose}>KNIT MY GIFT</Link>
              </Box>
              <Divider />
              <Box m={3} fontWeight="500" color={'teal.300'}>
                <Link>THE SCIENCE</Link>
              </Box>
              <Box m={3} ml={5} mt={1} _hover={{ fontWeight: "500" }}>
                <Link to="/newborn" onClick={onClose}>NEWBORNS</Link>
              </Box>
              <Box m={3} ml={5} mt={2}  _hover={{ fontWeight: "500" }}>
                <Link to="/earlydays" onClick={onClose}>3-4 MONTHS</Link>
              </Box>
              <Divider />              
              <Box m={3} _hover={{ fontWeight: "500" }}>
                <Link to="/about" onClick={onClose}>DESIGN YOUR BLANKET</Link>
              </Box>
              <Divider />    
              <Box m={3} _hover={{ fontWeight: "500" }}>
                <Link to="/scarves" onClick={onClose}>DESIGN A SCARF</Link>
              </Box>
              <Divider />
              <Box m={3} _hover={{ fontWeight: "500" }}>
                <Link to="/about" onClick={onClose}>ABOUT</Link>
              </Box>
              <Divider />
              <Box m={3} _hover={{ fontWeight: "500" }}>
                <Link to="/privacy" onClick={onClose}>PRIVACY</Link>
              </Box>
              <Divider />
              <Box m={3} _hover={{ fontWeight: "500" }}>
                <Link to="/terms" onClick={onClose}>TERMS & CONDITIONS</Link>
              </Box>
              <Divider />
              <Box m={3} _hover={{ fontWeight: "500" }}>
                <Link to="/returns" onClick={onClose}>RETURNS</Link>
              </Box>
              <Divider />
              <Box m={3} _hover={{ fontWeight: "500" }}>
                <Link to="/contact" onClick={onClose}>CONTACT US</Link>
              </Box>
              <Divider />
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default BurgerBar;
