import React from "react"
import { Amplify } from "aws-amplify";
import { Auth } from 'aws-amplify';
import CommonState from "./src/state/commonState";
import { Box } from "@chakra-ui/react"

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: 'eu-west-1',
    identityPoolRegion: 'eu-west-1',
    userPoolId: process.env.GATSBY_USER_POOL_ID,
    identityPoolId: process.env.GATSBY_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_WEB_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "api",
        region: 'eu-west-1',
        endpoint: process.env.GATSBY_API_ENDPOINT
      }
    ]
  }
})

export function wrapPageElement({ element, props }) {
  const { location } = props
  // const Layout = element.type.Layout ?? React.Fragment
  return (
    <CommonState pageContext={props.pageContext} location={location}>
      <Box width={'100%'} location={location} {...props}>{element}</Box>
    </CommonState>
  )
}

export const onRouteUpdate = (state, page, pages) => {
  Auth.currentAuthenticatedUser()
    .then(user => {
      console.log("USER", user)      
    })
    .catch(err => {
      window != undefined && window.localStorage.setItem('gatsbyUser', null)
    })
}
