import * as React from "react"
import { Box, Center, Container, Grid, GridItem, Stack, Icon, Text, VStack } from '@chakra-ui/react'
import { Link } from "gatsby"
// import { Box, Icon, Link } from '@chakra-ui/react';
import { FaInstagram } from 'react-icons/fa';

export const SiteFooter = () => (
  <Box fontFamily={'Roboto'} pt={6} pb={8} as="footer" color='white' maxW={'100%'} margin={0} bgColor={'black'} role="contentinfo" >
    {/* <Center>   */}
    <Container   minW={'300px'}>      
      <Center>
        <VStack>
        <Grid templateColumns="repeat(2, 1fr)" gap={[5,5,20,20]} fontSize={14}>
          <GridItem >
            <Text textAlign={'left'}>
              <VStack>
                <Link to="/about" mr={2}>
                  ABOUT
                </Link>
                <Link to="/design" mr={2}>
                  DESIGN MY BLANKET
                </Link>
                <Link to="/contact">
                  CONTACT US
                </Link>
              </VStack>
            </Text>
          </GridItem>
          <GridItem>
            <Text textAlign="left">
              <VStack>
                <Link to="/shipping" mr={2}>
                  SHIPPING
                </Link>
                <Link to="/returns" mr={2}>
                  RETURNS
                </Link>
                <Link to="/privacy">
                  PRIVACY
                </Link>
                <Link to="/terms">
                  TERMS & CONDITIONS
                </Link>
              </VStack>
            </Text>
          </GridItem>
        </Grid>
        <Box mt={3}>
          <Link href="https://www.instagram.com/knit_my_gift">
            <Icon as={FaInstagram} boxSize={6} />
          </Link>
        </Box >
        <Text fontSize="sm" color="fg.subtle" mt={2}>
              &copy; {new Date().getFullYear()} Knit My Gift. All rights reserved.
            </Text>
        {/* <Stack spacing={{ base: '4', md: '5' }}>            
      <Text fontSize="sm" color="fg.subtle">
        &copy; {new Date().getFullYear()} Knit my Gift. All rights reserved.
      </Text>
    </Stack> */}
        {/* </Center>   */}
        </VStack>
      </Center>
    </Container>
  </Box>
)