import React, { useEffect, setGlobal } from 'reactn'
import Auth from '@aws-amplify/auth'
import { Box, Center, Container } from '@chakra-ui/react'
import { SiteHeader } from '../components/header'
import { SiteFooter } from '../components/footer'
import { Buffer } from 'buffer';
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { Link } from 'gatsby'

const cookieName = 'kbCookieConsent'

function base64ToJson(base64String) {
  const json = Buffer.from(base64String, "base64").toString();
  return JSON.parse(json);
}

const CommonState = ({ pageContext, location, children }) => {



  const isSSR = typeof window === "undefined"

  if (!isSSR) {
    window.dataLayer = window.dataLayer || [];
  }
  function gtag() {
    console.log("GALL GTAG", arguments)
    window.dataLayer.push(arguments);
  }


  useEffect(() => {
    async function checkAuth() {
      const anonymousUser = await Auth.currentCredentials()
    }
    checkAuth()

    const cookieEnabled = getCookieConsentValue(cookieName)
    const trackContent = cookieEnabled === 'true' ? "granted" : "denied"

    gtag("consent", "default", {
      ad_storage: trackContent,
      analytics_storage: trackContent,
      functionality_storage: "granted",
      personalization_storage: trackContent,
      security_storage: "granted",
      wait_for_update: 500
    });

    console.log("CHECKING LOCAL STORAGE")
    const saved = localStorage.getItem("kmb");    
    if (saved) {
      const data = base64ToJson(saved);
      console.log("SETTING SAVED", data)
      setGlobal({
        loading: false,
        runCheckout: false,
        links: data.data.links,
        text: data.data.text,
        checkoutSrc: ""
      });
    } else {
      console.log("SETTING EMPTY")
      setGlobal({
        loading: false,
        runCheckout: false,
        links: [],
        text: '',
        checkoutSrc: ""
      });
    }
  }, [])


  return (
    <Box width="100%" maxW={'unset'} display='flex' minHeight='100vh' flexDirection='column' >
      <SiteHeader />
      <CookieConsent
        location="bottom"
        acceptOnScroll={true}
        acceptOnScrollPercentage={50}
        onAccept={(acceptedByScrolling) => {
          gtag("consent", "update", {
            ad_storage: "granted",
            analytics_storage: "granted",
            functionality_storage: "granted",
            personalization_storage: "granted",
            security_storage: "granted",
            wait_for_update: 500
          });
        }}
        buttonText="OK"
        cookieName={cookieName}
        style={{ background: "#000000" }}
        buttonStyle={{ background: "white", color: "#000000", fontWeight: 600, fontSize: "14px" }}
        expires={720}
      >We use cookies for a better experience.{" "}
        <span style={{ fontSize: "10px" }}>By accepting, you agree to our <Link to='/privacy'>privacy policy</Link>. </span>
      </CookieConsent>
      <Container id="body"
        // width={['90%','90%','80%','80%']} 
        maxW='100%' padding={0} flexGrow='1' marginTop={'120px'}
        // maxWidth={['90%','90%','80%','80%']}
        fontFamily="Open Sans">
        <Center>
          {children}
        </Center>
      </Container>
      <SiteFooter />
    </Box>

  )
}

export default CommonState
