exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-design-in-colour-js": () => import("./../../../src/pages/design-in-colour.js" /* webpackChunkName: "component---src-pages-design-in-colour-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-earlydays-js": () => import("./../../../src/pages/earlydays.js" /* webpackChunkName: "component---src-pages-earlydays-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newborn-js": () => import("./../../../src/pages/newborn.js" /* webpackChunkName: "component---src-pages-newborn-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-returns-js": () => import("./../../../src/pages/returns.js" /* webpackChunkName: "component---src-pages-returns-js" */),
  "component---src-pages-scarves-js": () => import("./../../../src/pages/scarves.js" /* webpackChunkName: "component---src-pages-scarves-js" */),
  "component---src-pages-shipping-js": () => import("./../../../src/pages/shipping.js" /* webpackChunkName: "component---src-pages-shipping-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

